import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItem(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/dashboard`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItemUser(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/announcements/by-user/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        markSeen(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/announcements/by-user/mark-as-seen/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
