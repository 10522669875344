<template>
  <b-modal
      no-close-on-backdrop
      centered
      hide-header-close
      ok-variant="dark"
      title="Duyuru Bilgileri"
      modal-class="modal-dark"
      v-model="isPreviewModal"
      size="lg">

    <div class="d-flex justify-content-start py-1">
      <b-avatar
          rounded
          size="200px"
          ref="previewEl"
          :src="getApiFile(previewData.imagePath)"
          variant="primary">
        <feather-icon icon="ImageIcon" size="150" v-if="previewData.imagePath === null"/>
      </b-avatar>

      <div class="d-flex flex-column ml-1">
        <div class="mb-1">
          <h4 class="mb-0">
            {{ previewData.title }}
          </h4>
          <p class="card-text text-muted">
            <date-time-column-table :data="previewData.startDate"></date-time-column-table>
          </p>
          <h5 class="mb-1">Duyuru İçeriği</h5>
          <p v-html="previewData && previewData.description && previewData.description !== '' ? previewData.description.replace(/\n/g, '<br>') : ''"></p>

          <p class="mt-2 mt-xl-0 w-100 border-bottom" v-if="previewData && previewData.documents && previewData.documents.length > 0">
            <strong>Eklenen Dosyalar</strong>
          </p>

          <b-link :href="getApiFile(data.filePath)" target="_blank" class="mr-1" v-for="(data, index) in previewData.documents" :key="index" v-if="previewData && previewData.documents && previewData.documents.length > 0">
            <b-img :src="require('@/assets/images/icons/doc.png')" width="16px" class="mr-50"/>
            <small>{{ data.name }}</small>
            <small class="text-muted font-small-2 ml-25">({{ data.fileSize }})</small>
          </b-link>

        </div>
      </div>
    </div>


    <template #modal-footer="">
      <b-row class="d-flex justify-content-end align-items-center">
        <b-button class="mr-1" variant="outline-secondary" @click="$emit('update:is-preview-modal', false)">
          <feather-icon icon="XCircleIcon" size="16"/>
          <span class="align-middle" role="button"> Kapat</span>
        </b-button>
      </b-row>
    </template>

    <Overlay :busy="busy"></Overlay>
  </b-modal>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {BAvatar, BButton, BImg, BLink, BModal, BRow} from "bootstrap-vue";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {recordStatusVariant, recordStatusFormat, getApiFile} from "@core/utils/filter"
import {ActiveColumnTable, DateTimeColumnTable} from "@/components/Table";

export default {
  components: {
    BImg, BLink,
    BAvatar,
    DateTimeColumnTable,
    ActiveColumnTable,
    Overlay,

    BRow,
    BButton,
    BModal
  },
  model: [
    {
      prop: 'isPreviewModal',
      event: 'update:is-preview-modal',
    },
    {
      prop: 'dataRecord',
      event: 'update:data-record',
    }
  ],
  props: {
    isPreviewModal: {
      type: Boolean,
      required: true,
    },
    dataRecord: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)
    const previewData = ref({});

    watch(() => props.dataRecord, (dataValue) => {
      if (dataValue.id !== undefined) {
        busy.value = true
        store.dispatch('store/previewItemUser', {id: dataValue.id}).then(response => {
          previewData.value = response.data.data
          store.dispatch('store/markSeen', {id: dataValue.id})
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
          if (error.response.status === 403) {
            emit('update:is-preview-modal', false)
            emit('update:data-record', {})
          }
        }).finally(message => {
          busy.value = false
        })
      }
    })

    return {
      busy,
      previewData,

      recordStatusFormat,
      recordStatusVariant,
      getApiFile
    }
  },
}
</script>
