<template>
  <div>
    <b-row class="match-height">
      <b-col xl="8" md="8" sm="8">
        <b-card class="card-app-design p-1" no-body>
          <div class="border-bottom pb-1 mb-1 d-flex">
            <feather-icon icon="ListIcon" size="19"/>
            <h4 class="mb-0 ml-50">Son İşlem Kayıtlarım</h4>
          </div>
          <b-card-body class="p-0">
            <div class="empty-card h-100" v-if="dataInfo.latestForms.length === 0">
              <b-media no-body>
                <b-media-aside class="mb-1">
                  <b-avatar size="100" variant="light-warning">
                    <feather-icon size="40" icon="ListIcon"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                </b-media-body>
              </b-media>
              <b-card-text class="font-small-3 mb-0">
                Kayıt bulunmamaktadır...
              </b-card-text>
            </div>
            <b-table
                :items="dataInfo.latestForms"
                :fields="latestFields"
                class="mb-0"
                striped responsive bordered hover
                v-if="dataInfo.latestForms.length > 0">

              <template #cell(title)="data">
                <div class="text-nowrap">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-avatar size="30" variant="light-primary">
                        <feather-icon size="16" icon="CodesandboxIcon"/>
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <b-card-text>
                        {{ data.value }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </div>
              </template>

              <template #cell(createdOn)="data">
                <date-time-column-table :data="data.value"></date-time-column-table>
              </template>

              <template #cell(userName)="data">
                <div class="text-nowrap">
                  <b-avatar src="" :text="avatarText(data.value)" :variant="`light-primary`" size="30px"/>
                  <span class="ml-1">{{ data.value }}</span>
                </div>
              </template>

              <template #cell(formStatusName)="data">
                <b-badge pill :variant="`light-${recordFormStatusVariant(data.item)}`" class="text-capitalize">
                  {{ data.value }}
                </b-badge>
              </template>

            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="4" md="4" sm="4">
        <b-card class="card-app-design p-1" no-body>
          <div class="border-bottom pb-1 mb-1 d-flex">
            <feather-icon icon="CastIcon" size="19"/>
            <h4 class="mb-0 ml-50">Duyurular</h4>
          </div>
          <b-card-body class="p-0">
            <div class="empty-card h-100" v-if="dataInfo.announcements.length === 0">
              <b-media no-body>
                <b-media-aside class="mb-1">
                  <b-avatar size="100" variant="light-warning">
                    <feather-icon size="40" icon="CastIcon"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                </b-media-body>
              </b-media>
              <b-card-text class="font-small-3 mb-0">
                Kayıt bulunmamaktadır...
              </b-card-text>
            </div>
            <div :class="index === (dataInfo.announcements.length - 1) ? 'employee-task d-flex justify-content-between align-items-center' : 'employee-task d-flex justify-content-between align-items-center border-bottom pb-1 mb-1'" v-for="(day,index) in dataInfo.announcements" :key="day.id">
              <b-media no-body>
                <b-media-aside class="mr-75">
                  <b-avatar
                      variant="light-primary"
                      rounded
                      size="42"
                      :src="getApiFile(day.imagePath)">
                    <feather-icon icon="ImageIcon" size="35" v-if="day.imagePath === null"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto custom-truncate">
                  <h6 class="mb-0">
                    {{ day.title }}
                  </h6>
                  <p class="text-truncate mb-0 text-muted">
                    {{ day.description }}
                  </p>
                </b-media-body>
              </b-media>
              <div class="d-flex align-items-center">
                <b-button size="sm" variant="outline-primary" @click="previewRecord(day)">
                  Görüntüle
                </b-button>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col xl="8" md="8" sm="8">
        <b-card class="card-app-design p-1" no-body>
          <div class="border-bottom pb-1 mb-1 d-flex">
            <feather-icon icon="SunsetIcon" size="19"/>
            <h4 class="mb-0 ml-50">Yaklaşan İzin Kayıtları</h4>
          </div>
          <b-card-body class="p-0">
            <div class="empty-card h-100" v-if="dataInfo.upcomingLeaveForms.length === 0">
              <b-media no-body>
                <b-media-aside class="mb-1">
                  <b-avatar size="100" variant="light-warning">
                    <feather-icon size="40" icon="SunsetIcon"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                </b-media-body>
              </b-media>
              <b-card-text class="font-small-3 mb-0">
                Kayıt bulunmamaktadır...
              </b-card-text>
            </div>
            <b-table
                :items="dataInfo.upcomingLeaveForms"
                :fields="waitingLeaveFields"
                class="mb-0 p-0"
                striped responsive bordered hover
                v-if="dataInfo.upcomingLeaveForms.length > 0">

              <template #cell(leaveTypeName)="data">
                <div class="text-nowrap">
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-avatar size="30" variant="light-primary">
                        <feather-icon size="16" icon="CodesandboxIcon"/>
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <b-card-text>
                        {{ data.value }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </div>
              </template>

              <template #cell(userName)="data">
                <div class="text-nowrap">
                  <b-avatar src="" :text="avatarText(data.value)" :variant="`light-primary`" size="30px"/>
                  <span class="ml-1">{{ data.value }}</span>
                </div>
              </template>

              <template #cell(startDateText)="data">
                <span v-if="data.value !== 'Bugün'">{{ data.value }}</span>
                <b-badge pill :variant="`light-warning`" class="text-capitalize" v-if="data.value === 'Bugün'">
                  {{ data.value }}
                </b-badge>
              </template>

              <template #cell(leaveDurationInDays)="data">
                <div class="text-nowrap">
                  <span>{{ data.value }} Gün</span>
                </div>
              </template>

            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="4" md="4" sm="4">
        <b-card class="card-app-design p-1" no-body>
          <div class="border-bottom pb-1 mb-1 d-flex">
            <feather-icon icon="CoffeeIcon" size="19"/>
            <h4 class="mb-0 ml-50">İzin Bakiyem</h4>
          </div>
          <b-card-body class="p-0">
            <vue-apex-charts
                id="revenue-report-chart"
                type="bar"
                height="280"
                :options="entitlementChart.chartOptions"
                :series="entitlementChart.series"
            />
          </b-card-body>
        </b-card>
      </b-col>

      <b-col xl="4" md="4" sm="4">
        <b-card class="card-transaction p-1" no-body>
          <div class="border-bottom pb-1 d-flex">
            <feather-icon icon="GiftIcon" size="19"/>
            <h4 class="mb-0 ml-50">Yaklaşan Doğum Günleri</h4>
          </div>
          <b-card-body class="p-0 pt-1">
            <div class="empty-card h-100" v-if="dataInfo.upcomingBirthDays.length === 0">
              <b-media no-body>
                <b-media-aside class="mb-1">
                  <b-avatar size="100" variant="light-warning">
                    <feather-icon size="40" icon="GiftIcon"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                </b-media-body>
              </b-media>
              <b-card-text class="font-small-3 mb-0">
                Kayıt bulunmamaktadır...
              </b-card-text>
            </div>
            <div :class="index === (dataInfo.upcomingBirthDays.length-1) ? 'transaction-item' : 'transaction-item border-bottom pb-1 mb-1'" v-for="(day, index) in dataInfo.upcomingBirthDays" :key="index">
              <b-media no-body>
                <b-avatar
                    src=""
                    :text="avatarText(day.title)"
                    :variant="`light-primary`"
                    size="40px"
                    class="mr-1"/>
                <b-media-body>
                  <h6 class="transaction-title">{{ day.title }}</h6>
                  <small>{{ day.subTitle }}</small>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder text-muted text-secondary">
                <span v-if="day.dateText !== 'Bugün'">{{ day.dateText }}</span>
                <b-badge pill :variant="`light-warning`" class="text-capitalize" v-if="day.dateText === 'Bugün'">
                  {{ day.dateText }}
                </b-badge>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="4" md="4" sm="4">
        <b-card class="card-transaction p-1" no-body>
          <div class="border-bottom pb-1 d-flex">
            <feather-icon icon="FeatherIcon" size="19"/>
            <h4 class="mb-0 ml-50">Resmi Tatiller</h4>
          </div>
          <b-card-body class="p-0 pt-1">
            <div class="empty-card h-100" v-if="dataInfo.upcomingOfficialHolidays.length === 0">
              <b-media no-body>
                <b-media-aside class="mb-1">
                  <b-avatar size="100" variant="light-warning">
                    <feather-icon size="40" icon="FeatherIcon"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                </b-media-body>
              </b-media>
              <b-card-text class="font-small-3 mb-0">
                Kayıt bulunmamaktadır...
              </b-card-text>
            </div>
            <div :class="index === (dataInfo.upcomingOfficialHolidays.length-1) ? 'transaction-item' : 'transaction-item border-bottom pb-1 mb-1'" v-for="(day, index) in dataInfo.upcomingOfficialHolidays" :key="day.title">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="42" variant="info">
                    <feather-icon size="18" icon="FeatherIcon"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">{{ day.title }}</h6>
                  <small>{{ day.subTitle }}</small>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder text-muted text-secondary">
                <span>{{ day.dateText }}</span>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="4" md="4" sm="4">
        <b-card class="card-app-design p-1" no-body>
          <div class="border-bottom pb-1 mb-1 d-flex">
            <feather-icon icon="UsersIcon" size="19"/>
            <h4 class="mb-0 ml-50">Çalışan Dağılımı</h4>
          </div>
          <b-card-body class="p-0 d-flex align-items-center justify-content-center">
            <vue-apex-charts height="220" :options="workPlaceChart.chartOptions" :series="workPlaceChart.series"/>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <Overlay :busy="busy"></Overlay>
    <preview :is-preview-modal.sync="isPreviewModal" :data-record.sync="dataPreviewRecord"></preview>

  </div>
</template>

<script>
import {BAvatar, BBadge, BButton, BCard, BCardBody, BCardHeader, BCardText, BCardTitle, BCol, BFormCheckbox, BLink, BMedia, BMediaAside, BMediaBody, BRow, BTable} from 'bootstrap-vue'
import store from "@/store"
import storeModule from "@/views/common/home/store"
import {onUnmounted, ref} from "@vue/composition-api";
import {formatDateTime, formatDate, formatTime, avatarText, getApiFile} from "@core/utils/filter"
import {DateColumnTable, DateTimeColumnTable} from "@/components/Table";
import VueApexCharts from 'vue-apexcharts'
import {$themeColors} from '@themeConfig'
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import {useToast} from "vue-toastification/composition";
import {toastMessage} from "@core/utils/utils";
import {recordFormStatusVariant} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import Preview from "@/views/announcement/Preview.vue";

export default {
  components: {
    Overlay,
    StatisticCardHorizontal,
    DateTimeColumnTable,
    DateColumnTable,
    VueApexCharts,
    Preview,
    BButton,
    BTable,
    BFormCheckbox,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMediaBody,
    BMediaAside,
    BMedia,
    BBadge,
    BAvatar,
    BCol,
    BRow,
    BCard,
    BCardText,
    BLink,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const toast = useToast()
    const busy = ref(false)

    const workPlaceChart = ref({})
    const entitlementChart = ref({})

    let workPlaceList = []
    let workPlaceValues = []
    let entitlementList = []
    let entitlementDayValues = []
    let entitlementUsedValues = []

    const dataInfo = ref({
      latestForms: [],
      leaveEntitlements: [],
      upcomingBirthDays: [],
      upcomingLeaveForms: [],
      upcomingOfficialHolidays: [],
      waitingApprovalForms: [],
      announcements: []
    })

    busy.value = true
    store.dispatch('store/fetchItem').then(response => {
      dataInfo.value = response.data.data

      workPlaceList = []
      workPlaceValues = []
      let workPlaceCount = 0.0;

      for (let key in response.data.data.employeeCountLists) {
        workPlaceList.push(response.data.data.employeeCountLists[key].workplaceName)
        workPlaceValues.push(parseFloat(response.data.data.employeeCountLists[key].count))
        workPlaceCount += parseFloat(response.data.data.employeeCountLists[key].count)
      }
      workPlaceChart.value = {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {show: false},
          comparedResult: [2, -3, 8],
          labels: workPlaceList,
          stroke: {width: 0},
          colors: [$themeColors.primary, $themeColors.warning],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      return parseInt(val)
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'TOPLAM',
                    formatter() {
                      return workPlaceCount
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
        series: workPlaceValues,
      }

      entitlementList = []
      entitlementDayValues = []
      entitlementUsedValues = []
      for (let key in response.data.data.leaveEntitlements) {
        entitlementList.push(response.data.data.leaveEntitlements[key].leaveTypeName)
        entitlementDayValues.push(parseFloat(response.data.data.leaveEntitlements[key].usedInDays))
        entitlementUsedValues.push(parseFloat(response.data.data.leaveEntitlements[key].remainingDays))
      }

      entitlementChart.value = {
        series: [
          {
            name: 'Kullanılan',
            data: entitlementDayValues,
          },
          {
            name: 'Kalan',
            data: entitlementUsedValues,
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: {show: false},
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: {show: true},
            },
          },
          xaxis: {
            categories: entitlementList,
            labels: {
              style: {
                fontSize: '0.81rem',
              },
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontWeight: 400,
            itemMargin: {
              horizontal: 5,
              vertical: 10
            },
            onItemClick: {
              toggleDataSeries: true
            },
            onItemHover: {
              highlightDataSeries: true
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: '60%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                fontSize: '0.86rem',
              },
            },
          },
        },
      }

    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const latestFields = [
      {key: 'title', label: 'İşlem', thStyle: {width: "1%"}},
      {key: 'createdOn', label: 'İşlem Zamanı'},
      {key: 'userName', label: 'Kullanıcı'},
      {key: 'formStatusName', label: 'Durum'},
    ];

    const waitingLeaveFields = [
      {key: 'userName', label: 'Kullanıcı'},
      {key: 'startDateText', label: 'İzin Tarihi'},
      {key: 'leaveTypeName', label: 'İzin Türü', thStyle: {width: "1%"}},
    ];

    const dataPreviewRecord = ref({})
    const isPreviewModal = ref(false)
    const previewRecord = (dataItem) => {
      dataPreviewRecord.value = dataItem
      isPreviewModal.value = true
    }

    return {
      dataInfo,
      busy,
      latestFields,
      waitingLeaveFields,
      workPlaceChart,
      entitlementChart,
      dataPreviewRecord,
      isPreviewModal,


      formatDateTime,
      formatDate,
      formatTime,
      avatarText,
      getApiFile,
      recordFormStatusVariant,
      previewRecord,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';

.empty-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.custom-truncate {
  max-width: 10vw;
}


</style>